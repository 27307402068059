import React, { useEffect, useState } from "react";
import { BuilderComponent, builder } from "@builder.io/react";

builder.init("ce0c102caa074d1daf7c79e25352ba57");

export default function Home() {
  const [, setNotFound] = useState(false);
  const [content, setContent] = useState(null);

  // get the page content from Builder
  useEffect(() => {
    async function fetchContent() {
      const content = await builder
        .get("page", {
          url: window?.location?.pathname,
          entry: "2bed47757f0947c58a37b94a17c8fd92",
        })
        .promise();

      setContent(content);
      setNotFound(!content);

      // if the page title is found,
      // set the document title
      if (content?.data.title) {
        document.title = content.data.title;
      }
    }
    fetchContent();
  }, []);

  // return the page when found
  return (
    <>
      {/* Render the Builder page */}
      <BuilderComponent model="page" content={content} />
    </>
  );
}
